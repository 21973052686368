import React from 'react';
import ReactDOM from 'react-dom';
import MainHeader from './components/header';
import  ContentViewer from './components/content'
export  const App = ()=>{
    document.title = "Firma Ya :: Validador de Documentos ::."

    return (
        <div className="contentPage">
            <MainHeader></MainHeader>
            <ContentViewer></ContentViewer>
        </div>
    ) 
  }


  export default App;