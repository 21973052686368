export const API_HOSTNAME_DOCUMENT_REQUEST = 'https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/documentmanager/api/DocumentManager';

export const API_HOSTNAME_QR_REQUEST = 'https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/qrmanager/api/QR/consultardocumento';
//export const API_HOSTNAME_DOCUMENT_REQUEST = 'http://qa.firmadigital.co/doc/documentmanager/api/DocumentManager';

//export const API_HOSTNAME_QR_REQUEST = 'https://ym9x2ku6x4.execute-api.us-east-2.amazonaws.com/dev/qrmanager/api/QR/consultardocumento';
//export const API_HOSTNAME_DOCUMENT_REQUEST = 'https://ym9x2ku6x4.execute-api.us-east-2.amazonaws.com/dev/documentmanager/api/DocumentManager';




//export const API_HOSTNAME_QR_REQUEST = 'https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/qr/qrmanager/api/QR/consultardocumento';
//export const API_HOSTNAME_DOCUMENT_REQUEST = 'https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/doc/documentmanager/api/DocumentManager';

