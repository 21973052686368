import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Captcha from './captcha';
import PanelSucess from './buttomPanelSuccesfull';
import Preloader from './preloader';

const HeaderContent =(props) =>{

const [file,setFile]=useState(null);
const [fileName,setFileName]=useState('');



return (
    <div >
         <div className="mainTitle"></div>
         <div className="textTitle"  >Validador de documentos</div>
         <div className="contentViewHeader">
                   
                    <div className="row contentHeadText">
                        
                        <div className="col-sm-7 divContentText">
                            El validador documental permite  verificar la autenticidad de los documentos firmados electrónicamente por
                            FirmaYa, Para realizar la comprobación, indroduzca los datos necesarios el cuadro de texto según  se muestre
                        </div>
                        <Captcha file={file} setFile={setFile} setFileName={setFileName}></Captcha>
                        <Preloader></Preloader>
                        <PanelSucess file={file} 
                                    fileName={fileName}
                                    setFile={setFile} 
                                    setFileName={setFileName}
                        >

                        </PanelSucess>
                        
                    </div>
                    
                    <p></p>
                    <div className="row" id="dvDocumetContent"  align="center">
                    
                            <input type="hidden" id="hdBase64" name="hdBase64" />
                            <input type="hidden" id="hdFileName64" name="hdFileName64" />
                       <div hidden={((file!==null)?false:true)} className="contentPDF">
                           <iframe id="ifrmDocument"  src={(file!==null)?URL.createObjectURL(file):''}  width="100%" height="800" ></iframe>
                        </div> 

                    
                    </div>
            </div>
    </div>
);   

}
export default HeaderContent;
