import React from 'react';
import ReactDOM from 'react-dom';
import ContentHeader from './headerContent';



const content =() =>
{


    return (
        <div>
            <ContentHeader></ContentHeader>
        </div>
    );
}
export default content;