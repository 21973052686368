import React, { Component }  from 'react';
import IconoPreloader  from '../resources/loader.gif'


const contentHeader =() =>{
    
    return (
        
                <div  id="dvPreloader" style={{display : 'none'}}  className="col-sm-6">
                    <div className="col-sm-12">
                        <img src={IconoPreloader}  width="100%" ></img>
                        <h4>Un momento por favor estamos consultando...</h4>
                    </div>
                </div>

                                          
                   
    ) ;
    }



export default contentHeader;

